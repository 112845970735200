// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "sqac-amplify-20190817123020-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2bvkvwwc07il6.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:ca85b12e-3a73-41b0-83f9-e009163ce776",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ux93CKcfK",
    "aws_user_pools_web_client_id": "370k85t69uqcu0gceg0i58avad",
    "oauth": {
        "domain": "sqac-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/,https://sqac.fanello.net/",
        "redirectSignOut": "http://localhost:4200/,https://sqac.fanello.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "sqac-amplify-user-data-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://3f3ttzjetbf6jlhehnivdaykdq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;
